<template>
  <UBanner>
    <article class="box">
      <h1 class="page-title">
        {{ localDocumentTitle }}
      </h1>

      <VCard>
        <VCardTitle>
          <VTextField
            v-model="search"
            append-icon="search"
            :label="$t('search.one')"
            single-line
            hide-details
          />
        </VCardTitle>

        <VDataTable
          :headers="headers"
          :items="groups"
          :loading="loading"
          :search="search"
          :page.sync="page"
          @update:page="updatePage"
        >
          <template #[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
        </VDataTable>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { getGroups } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'
import { UDate } from '@/utils/date'
import UBanner from '@components/UBanner.vue'

export default {
  name: 'TheGroups',

  components: {
    UBanner,
  },

  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'groups.two',
      loading: true,
      groups: [],
      search: '',
      page: 1
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('created.one'), value: 'created_at' },
        { text: this.$t('groups.one'), value: 'title' },
        { text: this.$t('courses.one'), value: 'course_title' },
        { text: this.$t('teachers.many'), value: 'teachers_count', sortable: false }
      ]
    }
  },

  created () {
    this.register()
  },

  methods: {
    formatDate: UDate.formatDate,

    async register () {
      const { groups } = await getGroups()
      this.groups = groups || []
      this.loading = false
      this.page = parseInt(this.$route.query.page || this.page)
    },

    updatePage (page) {
      const query = Object.assign({}, this.$route.query, {
        page
      })
      this.$router.replace({ query }).catch(() => {})
    }
  }
}
</script>
